import {
    Box,
    Button,
    Heading,
    Spinner,
    Text,
    Link,
    Flex,
    TiaModal,
    Image,
    Paragraph
} from '@asktia/tia-ui'
import { Link as RouterLink } from 'react-router-dom'
import { startOfToday, startOfDay, isBefore } from 'date-fns'
import { omit, merge } from 'lodash'
import { useEffect, ReactNode, useState, MutableRefObject } from 'react'
import { ModalTitle } from 'src/components/Blocks'
import { GroupedBookingSlots } from 'src/flows/AppointmentBooking/BookingSlot'
import { useAvailableSlots } from 'src/flows/AppointmentBooking/useAvailableSlots'
import {
    AvailableSlotsFilter,
    useAvailableSlotsFilter
} from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlotsFilter'
import {
    AppointmentProfile,
    AppointmentWithLocationDetails,
    AvailableSlot,
    QueryParams
} from 'src/types'
import { APPOINTMENT_PROFILE_NAMES } from 'src/globals'
import { useAmpli, useUserInfo } from 'src/hooks'
import { useModal } from 'react-modal-hook'
import howTimeSlotsWorkIllus from 'src/assets/how-time-slots-work-illustration.svg'
import { THREAD_LABELS } from 'src/globals'
import { ChevronLeft, ChevronRight } from 'src/components/Blocks/Icons'
import JoinWaitlistModal from 'src/components/JoinWaitlistModal'
import {
    TalkTherapyWaitlist,
    useTalkTherapyWaitlist
} from 'src/flows/AppointmentBooking/views/BookingTimeSelection/TalkTherapyWaitlist'
import { LastProvidersSlots } from './LastProvidersSlots'
import Logger from 'src/logger'

const NoSlots = (props: {
    appointmentProfile: AppointmentProfile
    rescheduledAppointment?: AppointmentWithLocationDetails
    scrollRef: MutableRefObject<HTMLDivElement | null>
    hasFilters: boolean
    clearFilters: Function
    filters?: {
        [k: string]: string | Date | never[]
    }
}) => {
    const { user } = useUserInfo()
    const { label, appointmentProfileUuid } = props.appointmentProfile
    const subject = `Book ${label}`
    const message = `I want to book ${label} and there are no available slots`

    Logger.info('No slots available', {
        appointmentProfileUuid,
        appointmentProfileLabel: label,
        userId: user?.id,
        filters: props.filters
    })

    return (
        <Box sx={{ color: 'mainBackground', position: 'relative' }}>
            <Heading h2 sx={{ color: 'mainBackground', fontSize: 18 }}>
                No appointments available
            </Heading>

            <AvailableSlotsFilter
                appointmentProfile={props.appointmentProfile}
                scrollRef={props.scrollRef}
                rescheduledAppointment={props.rescheduledAppointment}
            />

            <Box sx={{ fontSize: 3, mb: 6 }}>
                {props.hasFilters ? (
                    <>
                        <Paragraph mt="2">
                            There are no available time slots for this
                            appointment with your current selections.
                        </Paragraph>
                        <Paragraph sx={{ mb: 0 }}>
                            Try clearing or changing your selections above or
                            contact your Care Coordinator for help.
                        </Paragraph>
                    </>
                ) : (
                    <Paragraph sx={{ mb: 0 }}>
                        Please reach out to your Care Coordinator for help
                        booking your appointment.
                    </Paragraph>
                )}
            </Box>
            <Box sx={{ textAlign: 'center' }}>
                <Button
                    as={RouterLink}
                    // @ts-ignore: TS doesn't understand this is a RouterLink now
                    to={`/chat/new?label=${THREAD_LABELS.scheduling}&subject=${subject}&message=${message}`}
                    sx={{
                        minWidth: ['100%', '0%']
                    }}
                >
                    Contact Care Coordinator
                </Button>

                {props.hasFilters && (
                    <Box sx={{ mt: 4 }}>
                        <Link
                            sx={{ color: 'mainBackground70' }}
                            onClick={() => props.clearFilters()}
                            href="#"
                        >
                            Clear filters
                        </Link>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

const SlotsNav = ({
    slots,
    loading
}: {
    slots: AvailableSlot[]
    loading: boolean
}) => {
    const { filterValues, prevDateRange, nextDateRange, hasPrevPages } =
        useAvailableSlotsFilter()

    const startDate = filterValues.startDate as Date
    const canGoBack =
        hasPrevPages && !loading && startOfDay(startDate) > startOfToday()
    const canGoNext = !loading && slots.length > 0

    function goPrev() {
        if (canGoBack) {
            prevDateRange()
        }
    }

    function goNext() {
        if (canGoNext) {
            nextDateRange(slots[0].startTime, slots[slots.length - 1].startTime)
        }
    }

    return (
        <Flex
            sx={{
                justifyContent: 'space-between',
                mt: 4,
                mb: 5,
                color: 'mainBackground'
            }}
        >
            <Button
                onClick={goPrev}
                variant="unstyled"
                sx={{
                    textTransform: 'uppercase',
                    fontSize: 1,
                    display: 'flex',
                    alignItems: 'center',
                    opacity: canGoBack ? 1 : 0.2
                }}
            >
                <ChevronLeft color="white" />
                <Text sx={{ pl: 3, color: 'white' }}>Prev</Text>
            </Button>

            <Button
                onClick={goNext}
                variant="unstyled"
                sx={{
                    textTransform: 'uppercase',
                    fontSize: 1,
                    display: 'flex',
                    alignItems: 'center',
                    opacity: canGoNext ? 1 : 0.2
                }}
            >
                <Text sx={{ pr: 3, color: 'white' }}>Next</Text>
                <ChevronRight color="white" />
            </Button>
        </Flex>
    )
}

const HowTimeSlotsWorkModal = ({ hideModal }: { hideModal: Function }) => {
    return (
        <TiaModal>
            <ModalTitle
                title="Time slots that work for your schedule"
                hideModal={hideModal}
            />
            <Flex sx={{ my: 5, justifyContent: 'center' }}>
                <Image src={howTimeSlotsWorkIllus}></Image>
            </Flex>
            <Heading h4 sx={{ mb: 2 }}>
                Reserve your time slot
            </Heading>
            <Paragraph sx={{ mb: 5 }}>
                Tia offers weekly & bi-weekly therapy appointments. Select a
                time slot that will continually work for your schedule. If you
                decide with your therapist to change your cadence, our Care Team
                can help get you set up after your first appointment!
            </Paragraph>
            <Heading h4 sx={{ mb: 2 }}>
                If you need to reschedule
            </Heading>
            <Paragraph>
                No problem! You can cancel or reschedule in the Tia Member
                Portal.
            </Paragraph>
            <Paragraph sx={{ mb: 5 }}>
                Please note that we charge $50 for canceling or rescheduling
                within 48 hours of the appointment time, and $75 for no shows.
                These policies help to ensure we are best serving all of our Tia
                members.
            </Paragraph>
            <Button onClick={() => hideModal()} fullWidth>
                Got it!
            </Button>
        </TiaModal>
    )
}

const CampTiaHelperText = () => {
    return (
        <Paragraph sx={{ color: 'white' }}>
            Heads up! Our team will be out of office for a few days in April, so
            you might see limited availability below. If you’d like to begin a
            Talk Therapy Program before 4/12 and don’t see a start date that
            works for you, please{' '}
            <Link
                as={RouterLink}
                // @ts-ignore: TS doesn't understand this is a RouterLink now
                to="/chat/new"
            >
                reach out to a care coordinator
            </Link>{' '}
            to schedule.
        </Paragraph>
    )
}

const AvailableSlotsHelperText = ({
    appointmentProfile,
    isRescheduled
}: {
    appointmentProfile: AppointmentProfile
    isRescheduled: boolean
}) => {
    const displayTiaCampTextHelper =
        appointmentProfile.name ===
            APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_PROGRAM &&
        isBefore(new Date(), new Date(2022, 3, 15))

    let innerContent

    const [showModal, hideModal] = useModal(
        () => <HowTimeSlotsWorkModal hideModal={hideModal} />,
        []
    )

    if (
        appointmentProfile.careTypes.some(
            careType => careType.name === 'support_group'
        )
    ) {
        innerContent = (
            <Paragraph sx={{ color: 'white' }}>
                If your Group is a series, book and you'll be automatically
                enrolled for all sessions. If your Group is a drop-in, you'll
                only be joining 1 session at the date and time listed. Heads up,
                this service is in beta and we'd love your feedback.
            </Paragraph>
        )
    } else if (
        appointmentProfile.name ===
            APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_INTAKE ||
        appointmentProfile.name ===
            APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_PROGRAM
    ) {
        if (!isRescheduled) {
            innerContent = (
                <Paragraph sx={{ color: 'white' }}>
                    To get started, select an ongoing time slot that works for
                    your schedule. This time slot is for this and future
                    appointments.{' '}
                    <Link onClick={showModal}>
                        Learn more about how time slots work
                    </Link>
                </Paragraph>
            )
        }
    }

    return (
        <Text
            sx={{
                color: 'white',
                fontSize: 2
            }}
        >
            {innerContent}
            {displayTiaCampTextHelper ? <CampTiaHelperText /> : null}
        </Text>
    )
}

const SearchResults = (props: {
    slots: AvailableSlot[]
    appointmentProfile: AppointmentProfile
    rescheduleAppointmentUuid?: string
    rescheduledAppointment?: AppointmentWithLocationDetails
    scrollRef: MutableRefObject<HTMLDivElement | null>
    filters?: {
        [k: string]: string | Date | never[]
    }
}) => {
    const { hasFilters, clearFilters } = useAvailableSlotsFilter()
    const { showWaitlist } = useTalkTherapyWaitlist(props.appointmentProfile)

    if (showWaitlist) {
        return <TalkTherapyWaitlist />
    } else if (props.slots.length === 0) {
        return (
            <NoSlots
                appointmentProfile={props.appointmentProfile}
                hasFilters={hasFilters}
                rescheduledAppointment={props.rescheduledAppointment}
                scrollRef={props.scrollRef}
                clearFilters={() => clearFilters()}
                filters={props.filters}
            />
        )
    } else {
        return (
            <Box sx={{ mb: 4 }}>
                <GroupedBookingSlots
                    slots={props.slots}
                    sideScroll
                    hideCadence={!!props.rescheduleAppointmentUuid}
                />
            </Box>
        )
    }
}

export const AvailableSlots = (props: {
    appointmentProfile: AppointmentProfile
    rescheduledAppointment?: AppointmentWithLocationDetails
    rescheduleAppointmentUuid?: string
    appointmentSuggestionUuid?: string
    /**
     * overrides for user filterable query params
     */
    filterOverrides?: QueryParams
    /**
     * overrides for internal query params
     */
    queryOverrides?: QueryParams
    scrollRef: MutableRefObject<HTMLDivElement | null>
}) => {
    const [showWaitlistModal, hideWaitlistModal] = useModal(
        () => (
            <JoinWaitlistModal
                appointmentProfile={props.appointmentProfile}
                hideModal={hideWaitlistModal}
            />
        ),
        []
    )
    const { filterValues } = useAvailableSlotsFilter()

    const { ampli } = useAmpli()
    const [sAVTriggered, setSAVTriggered] = useState(false)

    const chosenFilters = omit(filterValues, 'startDate') as QueryParams
    const { slots, isLoading } = useAvailableSlots({
        appointmentProfileUuid: props.appointmentProfile.appointmentProfileUuid,
        startDate: filterValues.startDate as Date,
        maxSlots: 20,
        filters: merge(chosenFilters, props.filterOverrides),
        appointmentType: props.appointmentProfile.name,
        rescheduleAppointmentUuid: props.rescheduleAppointmentUuid,
        appointmentSuggestionUuid: props.appointmentSuggestionUuid,
        ...props.queryOverrides
    })

    const { showWaitlist } = useTalkTherapyWaitlist(props.appointmentProfile)

    const loading = isLoading
    const emptyResults = (!loading && slots.length < 1) || showWaitlist

    // Trigger select appointment viewed once loading is complete
    const resultsFound = !!slots.length
    useEffect(() => {
        if (props.appointmentProfile && !loading && !sAVTriggered) {
            ampli.selectAppointmentViewed({
                appointmentProfile:
                    props.appointmentProfile.appointmentProfileUuid,
                resultsShown: resultsFound,
                appointmentSuggestionUuid: props.appointmentSuggestionUuid!
            })
            setSAVTriggered(true)
        }
    }, [
        props.appointmentProfile,
        props.appointmentSuggestionUuid,
        resultsFound,
        loading,
        sAVTriggered,
        setSAVTriggered
    ])

    return (
        <Box
            sx={{
                bg: 'primaryButton',
                p: 5,
                borderRadius: [0, 2],
                // fixes mainBackground padding on mobile
                mb: ['-24px', 'initial']
            }}
        >
            {/* margin trickery gives us a scrolling reference that leaves space for the header */}
            <Box sx={{ mt: '-75px', mb: '75px' }} />
            {!emptyResults && (
                <Box sx={{ position: 'relative' }}>
                    <Heading h2 sx={{ color: 'white', mb: 5, fontSize: 20 }}>
                        Available appointments
                    </Heading>

                    <AvailableSlotsFilter
                        appointmentProfile={props.appointmentProfile}
                        scrollRef={props.scrollRef}
                        rescheduledAppointment={props.rescheduledAppointment}
                    />

                    <AvailableSlotsHelperText
                        appointmentProfile={props.appointmentProfile}
                        isRescheduled={!!props.rescheduleAppointmentUuid}
                    />
                    <SlotsNav slots={slots} loading={loading} />
                </Box>
            )}

            {loading ? (
                <Spinner color="white" />
            ) : (
                <>
                    <LastProvidersSlots
                        appointmentProfile={props.appointmentProfile}
                        filterValues={filterValues}
                        filterOverrides={props.filterOverrides}
                        queryOverrides={props.queryOverrides}
                        rescheduleAppointmentUuid={
                            props.rescheduleAppointmentUuid
                        }
                        appointmentSuggestionUuid={
                            props.appointmentSuggestionUuid
                        }
                    />
                    <SearchResults
                        slots={slots}
                        filters={filterValues}
                        appointmentProfile={props.appointmentProfile}
                        rescheduleAppointmentUuid={
                            props.rescheduleAppointmentUuid
                        }
                        scrollRef={props.scrollRef}
                        rescheduledAppointment={props.rescheduledAppointment}
                    />
                </>
            )}

            {!emptyResults ? (
                <SlotsNav slots={slots} loading={loading} />
            ) : null}
            {props.appointmentProfile.allowWaitlist && (
                <Box sx={{ textAlign: 'center' }}>
                    <Text sx={{ color: 'white', fontSize: 1 }}>
                        Don’t see a time you want?{' '}
                    </Text>
                    <Link
                        sx={{
                            color: 'white',
                            cursor: 'pointer',
                            fontSize: 1
                        }}
                        onClick={showWaitlistModal}
                    >
                        Join the waitlist!
                    </Link>
                </Box>
            )}
        </Box>
    )
}

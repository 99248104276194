import { useEffect, useMemo } from 'react'
import {
    Button,
    Heading,
    Flex,
    Select,
    Text,
    Box,
    ErrorLabel
} from '@asktia/tia-ui'
import { Link as RouterLink } from 'react-router-dom'
import { CenteredSpinner } from 'src/components/Blocks'
import { ThreadList } from './ThreadList'
import { THREAD_LABELS } from 'src/globals'
import { useInbox } from './InboxProvider'
import { useAmpli } from 'src/hooks'
import { Thread, useMessageThreads } from 'src/hooks/useMessageThreads'
import { partition } from 'lodash'

type SelectValue = {
    label: string
    value: string
}

export const Inbox = () => {
    const [labels, setLabels] = useInbox()
    const { isLoading, threads, isError } = useMessageThreads(labels)
    const [openThreads, closedThreads] = useMemo(() => {
        return partition<Thread>(
            threads,
            t => !t.status || t.status !== 'Archived'
        )
    }, [threads])
    const { ampli } = useAmpli()

    useEffect(() => {
        if (threads) {
            ampli.inboxViewed({
                closedConversations: closedThreads.length,
                currentConversations: openThreads.length,
                totalConversations: threads.length,
                unreadMessages: threads.filter(t => t.hasUnreadMessages).length
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threads])

    return (
        <>
            <Flex sx={{ justifyContent: 'center', mb: 5, mt: 2 }}>
                <Button
                    as={RouterLink}
                    // @ts-ignore: TS doesn't understand objects can be indexed by string
                    to="/chat/new"
                    fullWidth
                >
                    Send New Message
                </Button>
            </Flex>

            <Select<SelectValue>
                onChange={values => {
                    setLabels(
                        (values as SelectValue[]).map(
                            (e: SelectValue) => e.value
                        )
                    )
                }}
                isMulti
                validations={{ required: true }}
                placeholder="Filter messages by topic"
                name="label"
                options={Object.values(THREAD_LABELS).map(l => ({
                    label: l,
                    value: l
                }))}
            />

            {isLoading && <CenteredSpinner />}

            {!isLoading && isError && (
                <Box
                    sx={{
                        mt: 4,
                        mb: 0,
                        textAlign: 'center',
                        div: {
                            mt: 0,
                            mb: 0
                        }
                    }}
                >
                    <ErrorLabel show={isError}>
                        Something went wrong. Please try again.
                    </ErrorLabel>
                </Box>
            )}

            {!isLoading && !isError && (
                <Flex sx={{ flexDirection: 'column', gap: 4, mb: 4 }}>
                    <ThreadList
                        title="Current Conversations"
                        threads={openThreads}
                    />
                    <ThreadList
                        title="Closed Conversations"
                        threads={closedThreads}
                        closed
                    />
                </Flex>
            )}
            {!isLoading && !isError && threads.length === 0 && (
                <Box sx={{ mt: 2, mb: 4 }}>
                    <Heading h4 sx={{ color: 'supportText' }}>
                        No matches found
                    </Heading>
                    <Text sx={{ fontSize: 3, color: 'supportText' }}>
                        Try clearing your search, or start a new conversation
                        with your Care Team
                    </Text>
                </Box>
            )}
        </>
    )
}

import React, { Dispatch, SetStateAction, useContext, useState } from 'react'

const InboxContext = React.createContext<
    [string[], Dispatch<SetStateAction<string[]>>] | undefined
>(undefined)

export const InboxProvider = (props: React.PropsWithChildren<{}>) => {
    const state = useState<string[]>([])

    return (
        <InboxContext.Provider value={state}>
            {props.children}
        </InboxContext.Provider>
    )
}

export const useInbox = () => {
    const context = useContext(InboxContext)
    if (context == null) {
        throw new Error('useInbox must be within InboxProvider')
    }
    return context
}

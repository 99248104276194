import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks/useUserInfo'

export type ThreadMessage = {
    body: string
    created: string
    isArchived: boolean
    threadId: number
    documentReferenceUuids: string[]
    seenByIds?: number[]
    from: number
}

/* eslint-disable no-unused-vars */
export enum ThreadStatus {
    Archived = 'Archived',
    Complete = 'Complete',
    InProgress = 'In Progress',
    NeedsAction = 'Needs Action'
}
/* eslint-enable no-unused-vars */

export type Thread = {
    id: number
    hasUnreadMessages: boolean
    created: string
    subject: string
    lastMessage: ThreadMessage
    labels: string[]
    status?: ThreadStatus
    originatedFromStaff?: boolean
}
interface NewMessageThreadBody {
    message?: string
    subject: string
    label: string
}

/**
 * Creates a new thread with a message in it and returns the returned URL
 * from the redirect made by the BE
 */
async function createThread(patientId: number, body: NewMessageThreadBody) {
    const bd = JSON.stringify({
        subject: body.subject,
        label: body.label,
        type: 'chat',
        message: body.message
    })
    return await fetcher(`${BASE_API_URL}/chat/new`, bd, 'POST', {
        customReturn: async res => (res.redirected ? res.url : null)
    })
}

export function useCreateMessageThread() {
    const { user } = useUserInfo()
    const queryClient = useQueryClient()

    const mutation = useMutation(
        (body: NewMessageThreadBody) => createThread(user!.id, body),
        {
            retry: false,
            onSuccess: () => {
                queryClient.invalidateQueries(['get-threads', user!.id])
            }
        }
    )
    return mutation
}

async function getMessageThreads(patientId: number, labels: string[]) {
    let qs = `thread-type=chat&patient-id=${patientId}`

    if (labels.length > 0) {
        qs += `&labels=${encodeURIComponent(labels.join(','))}`
    }

    const json = await fetcher(`${BASE_API_URL}/messaging-api/threads?${qs}`)
    return json.threads // array of threads
}

export function useMessageThreads(labels?: string[]) {
    const { user, isLoading } = useUserInfo()
    const queryClient = useQueryClient()

    const query = useQuery<Thread[]>(
        ['get-threads', user?.id, ...(labels || [])],
        () => getMessageThreads(user!.id, labels || []),
        {
            staleTime: 1000 * 60 * 5, // refetch data after 5min
            enabled: !!user,
            onSuccess: (data: Thread[]) => {
                data.map(thread => {
                    queryClient.setQueryData(['get-thread', thread.id], thread)
                })
            }
        }
    )

    return {
        isLoading: isLoading || query.isLoading,
        threads: query.data || [],
        isError: query.isError
    }
}

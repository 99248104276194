import { format } from 'date-fns'
import { Thread } from 'src/hooks/useMessageThreads'
import { THREAD_LABELS, ThreadLabel } from 'src/globals'

const allPatientLabels = Object.values(THREAD_LABELS)

export function formatSubject(thread?: Thread) {
    if (thread?.subject == null) {
        return ''
    }
    let subject = thread.subject
    if (thread.originatedFromStaff && /^Message from/i.test(subject)) {
        const patientSafeLabels = Array.isArray(thread.labels)
            ? thread.labels.filter((threadLabel: string) =>
                  allPatientLabels.includes(threadLabel as ThreadLabel)
              )
            : []
        const prefix = patientSafeLabels.length
            ? patientSafeLabels.join(', ')
            : 'Tia Message'
        const date = format(new Date(thread.created), 'LLLL d, yyyy')
        subject = `${prefix}: ${date}`
    }
    return subject
}

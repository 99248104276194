import { Flex, Input, Label, Text, Textarea, useTiaInput } from '@asktia/tia-ui'
import { FC } from 'react'
import { ThemeUIStyleObject } from '@theme-ui/css'
import { RegisterOptions, UseFormMethods } from 'react-hook-form'
import {
    AttachmentClipIcon,
    CloseIcon,
    PhotoIcon
} from 'src/components/Blocks/Icons'

export interface ITextareaWithAttachment {
    textarea: {
        name: string
        placeholder?: string
    }

    attachment: {
        name: string
    }

    validations?: RegisterOptions
    showAttachmentButton?: boolean
    rows?: number
    sx?: ThemeUIStyleObject

    formMethods: UseFormMethods
}

const File = (props: { file: any; remove: () => void }) => {
    return (
        <Flex
            sx={{
                'mt': 2,
                'ml': 3,
                'width': '80%',
                'bg': 'secondaryBackground',
                'borderRadius': '8px',
                'padding': '6px 12px',
                'alignItems': 'center',
                'justifyContent': 'space-between',
                'cursor': 'pointer',
                ':hover': {
                    bg: 'inputOutlineHover'
                },
                ':hover svg': { opacity: '1 !important' }
            }}
            onClick={props.remove}
        >
            <PhotoIcon />
            <Text
                sx={{
                    fontSize: 2,
                    ml: 2,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                {props.file.name}
            </Text>
            <CloseIcon
                color="text"
                style={{
                    opacity: '0',
                    zoom: 0.8,
                    minWidth: '24px',
                    minHeight: '24px'
                }}
            />
        </Flex>
    )
}

export const TextareaWithAttachment: FC<ITextareaWithAttachment> = ({
    textarea,
    attachment,
    formMethods,
    validations,
    rows,
    sx,
    showAttachmentButton = true
}) => {
    const { borderColor } = useTiaInput({
        name: textarea.name,
        validations,
        required: true
    })
    const { watch, setValue, trigger } = formMethods
    const files = watch(attachment.name)

    const currentFile = files && files[0]

    const removeAttachment = () => {
        setValue(attachment.name, undefined)
        // re-validate form
        trigger()
    }

    return (
        <>
            <Flex
                sx={{
                    'bg': 'white',
                    'width': '100%',
                    'position': 'relative',
                    'border': '1px solid',
                    'borderColor': borderColor,
                    'borderRadius': '10px',
                    'flexDirection': 'column',
                    ':hover': {
                        borderColor: 'inputOutlineHover'
                    },
                    ':focus-within': {
                        borderColor: 'inputOutlineSelected'
                    },
                    'mb': validations ? 16 : undefined
                }}
            >
                {currentFile && (
                    <File file={currentFile} remove={removeAttachment} />
                )}

                <Input
                    type="file"
                    name={attachment.name}
                    id="message-file-upload"
                    sx={{ display: 'none' }}
                    accept=".jpg,.jpeg,.png,.heic,.pdf,application/pdf"
                />

                <Flex sx={{ position: 'relative' }}>
                    {showAttachmentButton && (
                        <Label
                            htmlFor="message-file-upload"
                            sx={{
                                position: 'absolute',
                                top: 3,
                                left: 1,
                                width: 24,
                                height: 24,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '100%',
                                cursor: 'pointer'
                            }}
                        >
                            <AttachmentClipIcon
                                color="inactiveText"
                                hoverColor="text"
                            />
                        </Label>
                    )}

                    <Textarea
                        name={textarea.name}
                        placeholder={textarea.placeholder}
                        errorMessage=""
                        rows={rows}
                        validations={validations}
                        sx={{
                            pl: showAttachmentButton ? 35 : 16,
                            mb: 0,
                            border: 'none',
                            // Don't allow the text area to resize his width
                            minWidth: '100%',
                            maxWidth: '100%',
                            minHeight: '100px',
                            maxHeight: '300px',
                            ...sx
                        }}
                    />
                </Flex>
            </Flex>
        </>
    )
}

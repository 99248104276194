import { useQuery, useMutation, useQueryClient } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

// NOTE the name fileAttachment is very important
// the backend multer middleware expects multiform data
// with the file associated with this name.
// in the future the backend should tell the
// the member portal what field name it needs to use
export const FILE_UPLOAD_NAME = 'fileAttachment'

// TODO: unify Message type with TM-4097
export type ThreadMessage = {
    body: string
    created: string
    isArchived: boolean
    threadId: number
    documentReferenceUuids: string[]
    seenByIds?: number[]
    from: number
}

/* eslint-disable no-unused-vars */
export enum ThreadStatus {
    Archived = 'Archived',
    Complete = 'Complete',
    InProgress = 'In Progress',
    NeedsAction = 'Needs Action'
}
/* eslint-enable no-unused-vars */

export type Thread = {
    id: number
    hasUnreadMessages: boolean
    created: string
    subject: string
    lastMessage: ThreadMessage
    labels: string[]
    status?: ThreadStatus
    users: {
        id: number
        givenName: string
        familyName: string
        isPatient: boolean
        displayName: string
    }[]
}

async function getMessageThread(threadId: number) {
    const json = await fetcher(
        `${BASE_API_URL}/messaging-api/threads/${threadId}`
    )
    return json as Thread
}

export function useThread(threadId: number) {
    const queryClient = useQueryClient()
    const query = useQuery<Thread>(
        ['get-thread', threadId],
        () => getMessageThread(threadId),
        {
            staleTime: 1000 * 60 * 5, // refetch data after 5min
            onSuccess: () => {
                queryClient.invalidateQueries('get-threads')
            }
        }
    )

    return {
        isLoading: query.isLoading,
        thread: query.data,
        isError: query.isError
    }
}

async function getMessagesForThread(threadId: number) {
    const json = await fetcher(
        `${BASE_API_URL}/chat/thread/${threadId}?getMessagesData=true`
    )

    return json.messages.sort((m1: Message, m2: Message) => {
        return new Date(m1.created) >= new Date(m2.created) ? 1 : -1
    })
}

export type DocumentReference = {
    actor: { id: number; displayName: string }
    category: string
    clinicUuid: string
    createdAt: string
    filename: string
    isArchived: boolean
    mimeType: string
    patient: { id: number; displayName: string }
    updatedAt: string
    url: string
    uuid: string
}
export type Message = {
    id: number
    from: number
    fromInitials: string
    fromRobot: boolean
    threadId: number
    body?: string
    created: string
    type: 'chat'
    seenByIds?: number[]
    isArchived: boolean
    // TODO: not actually seeing this in messages is it optional
    isUnread: boolean
    documentReferenceUuids?: string[]
    documentReferences?: DocumentReference[]
}

export function useMessagesForThread(threadId: number) {
    const queryClient = useQueryClient()
    const query = useQuery<Message[]>(
        ['get-messages-for-thread', threadId],
        () => getMessagesForThread(threadId),
        {
            staleTime: 1000 * 60 * 5,
            onSuccess: () => {
                queryClient.invalidateQueries('get-threads')
            }
        }
    )

    return {
        isLoading: query.isLoading,
        messages: query.data || [],
        isError: query.isError
    }
}

// TODO NOT SURE HOW TO RESOLVE THIS ANY
export type CreateMessagePayload = {
    [FILE_UPLOAD_NAME]?: FileList
    body?: string
}
export async function createMessageForThread(
    threadId: number,
    values: CreateMessagePayload
) {
    const fileUpload = values[FILE_UPLOAD_NAME]
    const formData = new FormData()

    if (fileUpload?.[0]) {
        formData.append(FILE_UPLOAD_NAME, fileUpload[0])
    }

    const message = values.body ? values.body : ''
    formData.append('message', message)
    formData.append('type', 'chat')

    // these are old endpoints that were being used
    // as part of a form submission that we wanted a full page reload
    // we don't need that anymore
    // there is a follow up task to determine if these endpoints are used
    // anywhere else that still needs the redirect
    formData.append('preventRedirect', 'true')

    return await fetcher(
        `${BASE_API_URL}/chat/thread/${threadId}`,
        formData,
        'POST',
        {},
        false
    )
}

export function useCreateMessageForThread(threadId: number) {
    const queryClient = useQueryClient()

    const mutation = useMutation(
        (values: CreateMessagePayload) => {
            return createMessageForThread(threadId, values)
        },
        {
            retry: false, // TODO: should we retry
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'get-messages-for-thread',
                    threadId
                ])
            }
        }
    )
    return mutation
}

import { Box, Heading, Flex, Text, Grid } from '@asktia/tia-ui'
import { AttachementIcon, ChevronRight } from 'src/components/Blocks/Icons'
import { formatDistanceToNow, differenceInDays, format } from 'date-fns'
import { useNavigation } from 'src/hooks/useNavigation'
import { THREAD_LABELS } from 'src/globals'
import { useInbox } from './InboxProvider'
import { Thread } from 'src/hooks/useMessageThreads'
import pluralize from 'pluralize'
import { useUserInfo } from 'src/hooks'
import { formatSubject } from '../utils'

const Tag = ({ label }: { label: string }) => {
    const [labels] = useInbox()
    return (
        <Box
            sx={{
                bg: 'mainBackground',
                border: theme => `1px solid ${theme.colors?.inputOutline}`,
                borderRadius: 37,
                py: 1,
                px: 3
            }}
        >
            <Text
                sx={{
                    color: 'text',
                    fontWeight: labels.includes(label) ? 'bold' : '400',
                    fontSize: 2
                }}
            >
                {label}
            </Text>
        </Box>
    )
}

const Tags = ({ labels = [] }: { labels: string[] }) => {
    const tags = labels
        // @ts-ignore
        .filter(l => Object.values(THREAD_LABELS).includes(l))
        .map(label => <Tag key={`tag-${label}`} label={label} />)
    return (
        <Flex sx={{ gap: 2, justifyContent: 'flex-start', flexWrap: 'wrap' }}>
            {tags}
        </Flex>
    )
}

const Attachments = ({ thread, closed }: ThreadPreviewProps) => {
    const count = thread.lastMessage.documentReferenceUuids?.length
    const attachments = pluralize('Attachment', count)
    const files = pluralize('file', count, true)
    const label = `${attachments}: ${files}`

    if (!count) {
        return null
    }

    return (
        <Flex
            sx={{
                color: closed ? 'supportText' : 'supportText',
                fontSize: 1,
                alignItems: 'center',
                gap: 1
            }}
        >
            <AttachementIcon /> {label}
        </Flex>
    )
}

const BodyMessage = ({ thread, closed }: ThreadPreviewProps) => {
    return (
        <Text
            sx={{
                color: closed ? 'supportText' : 'text',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                fontSize: 3,
                maxHeight: '42px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}
        >
            {thread.lastMessage.body}
        </Text>
    )
}

const NewMessageIndicator = () => (
    <Box
        sx={{
            position: 'relative',
            top: '15px',
            left: '-14px',
            width: '8px',
            height: '8px',
            bg: 'communicationBanner',
            borderRadius: '4px'
        }}
    />
)

const Subject = ({
    thread,
    closed,
    userHasRead
}: ThreadPreviewProps & { userHasRead: boolean }) => {
    let headerColor = 'supportText' // assuming it is closed
    if (!closed) {
        headerColor = 'text'
    }
    const subject = formatSubject(thread)
    return (
        <Heading
            h4
            sx={{
                color: headerColor,
                mb: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}
        >
            {subject}
        </Heading>
    )
}

const MessageHeader = ({ thread, closed }: ThreadPreviewProps) => {
    const threadTime = new Date(thread.created)
    const displayTime =
        differenceInDays(new Date(), threadTime) > 7
            ? format(threadTime, 'LLL d, hh:mmaaa')
            : formatDistanceToNow(threadTime, {
                  addSuffix: true
              })

    const { user } = useUserInfo()
    const userHasRead =
        user && thread.lastMessage?.seenByIds?.includes?.(user.id)

    return (
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
            <Box>
                {!userHasRead && <NewMessageIndicator />}
                <Subject
                    thread={thread}
                    closed={closed}
                    userHasRead={!!userHasRead}
                />
            </Box>
            <Grid
                sx={{
                    flexShrink: 0,
                    alignItems: 'center',
                    ml: 2,
                    gridTemplateColumns: 'auto 10px'
                }}
            >
                <Text
                    sx={{
                        color: 'supportText',
                        fontSize: 1
                    }}
                >
                    {displayTime}
                </Text>
                <ChevronRight color="supportText" />
            </Grid>
        </Flex>
    )
}

// TODO: change rest of names to thread preview ish
const ThreadPreview = ({ thread, closed, title }: ThreadPreviewProps) => {
    const { navigate } = useNavigation()

    const onClick = () => {
        navigate(`/chat/thread/${thread.id}`)
    }

    return (
        <Flex
            onClick={onClick}
            sx={{
                'borderTop': '1px solid',
                'borderColor': 'inputOutline',
                'px': 5,
                'cursor': 'pointer',
                'py': 3,
                'bg': closed ? 'secondaryBackground' : 'cardBackground',
                '&:hover': {
                    backgroundColor: 'mainBackground'
                },
                'flexDirection': 'column',
                'gap': 2
            }}
        >
            <MessageHeader thread={thread} closed={closed} />
            <BodyMessage thread={thread} closed={closed} />
            <Attachments thread={thread} closed={closed} />
            <Tags labels={thread.labels} />
        </Flex>
    )
}

type ThreadPreviewProps = { thread: Thread; closed: boolean; title?: string }

const ThreadListHeader = ({ title }: { title: string }) => (
    <Heading
        h3
        sx={{ px: 5, py: 3, color: 'supportText', m: 0, fontWeight: '350' }}
    >
        {title}
    </Heading>
)

type ThreadListProps = {
    title: string
    threads: Thread[]
    closed?: boolean
}

const ThreadPreviews = ({ threads, closed }: ThreadListProps) => (
    <>
        {threads.map(thread => (
            <ThreadPreview key={thread.id} thread={thread} closed={!!closed} />
        ))}
    </>
)

export const ThreadList = ({ title, threads, closed }: ThreadListProps) => {
    if (threads.length === 0) {
        return null
    }

    return (
        <Box>
            <ThreadListHeader title={title} />
            <ThreadPreviews threads={threads} closed={!!closed} title={title} />
        </Box>
    )
}

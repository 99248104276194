import React, { useEffect } from 'react'
import { CenteredSpinner } from 'src/components/Blocks'
import { PageHeader } from 'src/components/PageHeader'
import { LayoutGrid, Container, Box, ErrorLabel } from '@asktia/tia-ui'
import { useMessageThreads } from 'src/hooks/useMessageThreads'
import { NoMessagesInbox } from './NoMessagesInbox'
import { Inbox as InboxContainer } from './Inbox'
import { BackButton } from 'src/components/Blocks/BackButton'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { useUserInfo } from 'src/hooks'
import { PreviewExplanation } from 'src/components/Preview/PreviewExplanation'
import { useModal } from 'react-modal-hook'
import { InboxProvider } from './InboxProvider'

export const Inbox = () => {
    const { user } = useUserInfo()

    const [showPreviewExplanationModal] = useModal(
        () => <PreviewExplanation showWhatsMissing={true} />,
        []
    )

    useEffect(() => {
        if (user?.membershipSignupStatus === 'preview') {
            showPreviewExplanationModal()
        }
    }, [user])

    const { isLoading, threads, isError } = useMessageThreads()

    return (
        <Body>
            <PageHeader mobileBack={<BackButton href="/" variant="white" />} />
            <NavBarHeading titleAlign="left" href="/" showBackButton>
                Care Team Messages
            </NavBarHeading>
            <Container>
                <LayoutGrid columns="one">
                    {isLoading && <CenteredSpinner />}
                    {!isLoading && isError && (
                        <Box
                            sx={{
                                mt: 4,
                                mb: 0,
                                textAlign: 'center',
                                div: {
                                    mt: 0,
                                    mb: 0
                                }
                            }}
                        >
                            <ErrorLabel show={isError}>
                                Something went wrong. Please try again.
                            </ErrorLabel>
                        </Box>
                    )}
                    {!isLoading && !isError && threads.length === 0 && (
                        <NoMessagesInbox />
                    )}
                    {!isLoading && !isError && threads.length > 0 && (
                        <InboxProvider>
                            <InboxContainer />
                        </InboxProvider>
                    )}
                </LayoutGrid>
            </Container>
        </Body>
    )
}
